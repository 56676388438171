import router from '../../router'
import { ref} from 'vue'
// 用来存放调用此js的vue组件实例（this）
let vm = null

export var sendThis = ( _this )=> {
  vm = _this
}
//软件状态操作
export var softwareStructureState={
  width:90,
  type: 4, //带颜色的文字
  property:"state",
  label:"发布状态",
  sucText:"已发布",
  danger:"未发布",
  disabledText:"已取消"
}
//软件管理操作
export var softwareOperation={
  width:140,
  type: 3, //操作
  label:"操作",
  buttonList:[{
    buttonType:"primary",
    label:"编辑",
    callBackFn:"editData",
    isShowHide:1,//动态调整是否存在
    clickFn(row){
      vm.editData(row);
    }
  },{
    buttonType:"primary",
    label:"重传",
    callBackFn:"edit",
    isShowHide:1,//动态调整是否存在
    clickFn(row){
      vm.edit(row);
    }
  },{
    buttonType:"primary",
    sucLabel:"取消发布", //禁用
    dangerLabel:"发布", //启用
    disabledLabel:"已取消", //不允许点击
    dialog: {
      data:{
        type:1,
        title:"发布",
        content:"确定要发布吗？"
      }
    },
    labelData:{
      labelType:2,//三种状态
    },
    clickFn(row){
      vm.changeState(row);
    }
  }
  ]
}
