import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "table_top_box"
};
var _hoisted_2 = {
  key: 1,
  style: {
    "display": "flex",
    "align-items": "center",
    "justify-content": "center",
    "font-size": "14px",
    "color": "#bcbcbc",
    "height": "calc(100vh - 190px)"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_filter_bar = _resolveComponent("filter-bar");
  var _component_el_button = _resolveComponent("el-button");
  var _component_el_col = _resolveComponent("el-col");
  var _component_el_row = _resolveComponent("el-row");
  var _component_soft_common_table = _resolveComponent("soft-common-table");
  var _component_add_offline_soft = _resolveComponent("add-offline-soft");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_filter_bar, {
    filter: $data.filter,
    onSearchFun: $options.search,
    onReloadFun: $options.reload
  }, null, 8, ["filter", "onSearchFun", "onReloadFun"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_el_row, {
    gutter: 20,
    justify: "space-between"
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_col, {
        span: 8
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[0] || (_cache[0] = function ($event) {
              return $options.showAddResource(2, 'Windows');
            })
          }, {
            default: _withCtx(function () {
              return [_createTextVNode("添加EXE软件")];
            }),
            _: 1
          }), _createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[1] || (_cache[1] = function ($event) {
              return $options.showAddResource(2, 'Android');
            })
          }, {
            default: _withCtx(function () {
              return [_createTextVNode("添加APK软件")];
            }),
            _: 1
          })];
        }),
        _: 1
      })];
    }),
    _: 1
  })]), $data.tableData && $data.tableData.length > 0 ? (_openBlock(), _createBlock(_component_soft_common_table, {
    tableData: $data.tableData,
    key: $data.tableKey,
    initTable: $data.softwareOfflineSoft,
    total: $data.total
  }, null, 8, ["tableData", "initTable", "total"])) : _createCommentVNode("", true), !$data.tableData || $data.tableData.length == 0 ? (_openBlock(), _createElementBlock("div", _hoisted_2, " 暂无数据 ")) : _createCommentVNode("", true), _createVNode(_component_add_offline_soft, {
    ref: "childRef",
    accept: $data.accept,
    title: '上传软件',
    formFile: $data.formFile,
    onConfirm: $options.confirmClick
  }, null, 8, ["accept", "formFile", "onConfirm"])]);
}